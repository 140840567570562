import(/* webpackMode: "eager" */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/public/icons/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/src/components/AppHeader/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/src/components/HighOrderComponent/index.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/src/context/providers/DataProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/src/context/providers/LanguageProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/src/context/providers/UIProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/icarmenia.am/httpdocs/frontend/src/libs/styled-registry.js");
